import styles from "../../assets/stylesheets/Why.module.css";

export default function Why(props) {
  return (
    <div id={props.id} className={styles.journey__why__container}>
      <p className={styles.journey__why__header}>WHY JOURNEY?</p>
      <p className={styles.journey__why__text}>
        <span>"</span>Our why is to encourage believers on the journey to
        discover their God-given purposes.<span>"</span>
      </p>
    </div>
  );
}
