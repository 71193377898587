import { Route, Routes } from "react-router-dom";
import NavbarHeader from "./components/NavbarHeader";
import AppContainer from "./AppContainer";
import Payment from "./components/engagement/Payment";
import { useRef } from "react";
// import GoToButton from "./components/ui/GoToButton";
import CoachingMoreInfo from "./components/engagement/more-info/CoachingMoreInfo";
import EventsMoreInfo from "./components/engagement/more-info/EventsMoreInfo";
import SpeakingMoreInfo from "./components/engagement/more-info/SpeakingMoreInfo";

function App() {
  const topRef = useRef(null);

  return (
    <>
      <Routes>
        <Route path="/" element={<NavbarHeader topRef={topRef} />}>
          <Route index element={<AppContainer />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/more-info/coaching" element={<CoachingMoreInfo />} />
          <Route path="/more-info/events" element={<EventsMoreInfo />} />
          <Route path="/more-info/speaking" element={<SpeakingMoreInfo />} />
        </Route>
      </Routes>
      {/* <GoToButton displayAfter={200} target={topRef} /> */}
    </>
  );
}

export default App;
