import styles from "../../../assets/stylesheets/MoreInfo.module.css";

export default function CoachingMoreInfo() {
  return (
    <div className={styles.journey__moreInfo__container}>
      <p className={styles.journey__moreInfo__header}>COACHING</p>
      <p className={styles.journey__moreInfo__text}>
        Finding God's purpose for one's life can indeed be a challenging and
        deeply personal endeavor. Recognizing the significance of this quest,
        Journey Co. goes above and beyond by offering comprehensive coaching
        services specifically designed to assist believers in their pursuit of
        discovering and connecting with God's purpose for their lives. This
        invaluable resource equips participants with the necessary guidance and
        support to navigate this transformative journey. Journey Co.'s coaching
        program is carefully structured to provide a step-by-step process that
        unfolds over the course of twelve weeks. Throughout this journey,
        participants engage in regular sessions with a skilled and compassionate
        coach who serves as a trusted companion on their path of self-discovery.
        These coaching sessions serve as a safe space for individuals to explore
        their aspirations, reflect on their personal experiences, and uncover
        the unique ways in which God's purpose may be unfolding in their lives.
      </p>
      <p className={styles.journey__moreInfo__text}>
        Within the weekly coaching sessions, participants receive a wealth of
        teachings, resources, and tools curated specifically to illuminate the
        path toward finding their God-given purpose. The coaching process draws
        from a variety of sources, including scriptural insights, practical
        exercises, and inspirational materials, all tailored to the individual's
        needs. Through thought-provoking discussions, introspective exercises,
        and self-reflection, participants are gently guided to delve deeper into
        their faith and discern the whispers of God's purpose within their
        hearts.
      </p>
      <p className={styles.journey__moreInfo__text}>
        The coaching program at Journey Co. not only provides valuable teachings
        and resources but also emphasizes the significance of encouragement and
        support. Throughout the twelve-week journey, participants receive
        consistent encouragement from their coach, who acts as a cheerleader,
        mentor, and accountability partner. This unwavering support system
        ensures that individuals remain motivated and empowered to stay
        committed to the process of discovery and transformation.
      </p>
      <p className={styles.journey__moreInfo__text}>
        By embarking on this journey with a coach, participants not only gain
        valuable insights and self-awareness but also experience the profound
        transformation that comes from aligning their lives with God's purpose.
        The coaching program at Journey Co. serves as a catalyst for personal
        growth, enabling individuals to step into their unique calling and live
        a life of significance and fulfillment.
      </p>
      <p className={styles.journey__moreInfo__text}>
        Ultimately, Journey Co.'s coaching services provide a transformative
        opportunity for believers to deepen their connection with God and
        navigate the complexities of purpose with confidence and clarity. By
        partnering with a skilled coach, participants embark on a profound
        exploration of their spiritual journey, unearthing their passions,
        talents, and unique calling in a way that brings them closer to God and
        ignites a sense of purpose that permeates every aspect of their lives.
      </p>
      <a
        className={styles.journey__moreInfo__link}
        target="_blank"
        rel="noreferrer"
        href="https://youtu.be/fd4jhcYwXAs"
      >
        WATCH COACHING INTRODUCTION VIDEO
      </a>
      <a
        tabIndex={0}
        target="_blank"
        rel="noreferrer"
        href="https://mailchi.mp/9dc701000a92/coachingjourney"
        className={styles.journey__join__button}
      >
        SIGN UP
      </a>
    </div>
  );
}
