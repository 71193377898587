import mountainVideo from "../assets/videos/mountains.mp4";
import styles from "../assets/stylesheets/HeaderBackground.module.css";

export default function HeaderBackground() {
  return (
    <div className={styles.journey__header__container}>
      <video
        preload="auto"
        autoPlay
        loop
        muted
        className={styles.journey__header__video}
      >
        <source src={mountainVideo} type="video/mp4" />
      </video>
    </div>
  );
}
