export default function Chat() {
  return (
    <>
      <chat-widget
        location-id="9oTZiN1SNxA8etpl0VP2"
        prompt-avatar="https://widgets.leadconnectorhq.com/chat-widget/assets/defaultAvatar.png"
        agency-name="Rule Your Business"
        agency-website="www.ruleyourbusiness.com"
      ></chat-widget>
    </>
  );
}
