import styles from "../../../assets/stylesheets/MoreInfo.module.css";

export default function SpeakingMoreInfo() {
  return (
    <div className={styles.journey__moreInfo__container}>
      <p className={styles.journey__moreInfo__header}>SPEAKING</p>
      <p className={styles.journey__moreInfo__text}>
        With over 20 years of speaking experience, Jon has honed his ability to
        connect with diverse audiences on a profound level. Throughout his
        extensive leadership journey, he has cultivated a deep passion for
        sharing the wisdom and teachings of the Bible, which shines through in
        his engaging and impactful presentations. Whether he is addressing a
        small gathering of youth in search of guidance and inspiration, a large
        audience at a church conference yearning to deepen their faith, or
        business leaders seeking spiritual insights in their professional lives,
        Jon's messages resonate deeply with his listeners.
      </p>
      <p className={styles.journey__moreInfo__text}>
        What sets Jon apart is his remarkable talent for tailoring his delivery
        to meet the specific needs and interests of each unique audience. He
        understands that everyone is on their own faith journey, and he ensures
        that his words uplift and motivate individuals along their path. His
        approachability, authenticity, and relatable storytelling captivate his
        listeners, drawing them in and making him a sought-after speaker. Those
        who have had the privilege of hearing him speak are left with a lasting
        impact, inspired by his powerful messages.
      </p>
      <p className={styles.journey__moreInfo__text}>
        Jon's wealth of knowledge, coupled with his ability to connect with
        people from all walks of life, makes him a dynamic speaker. His
        unwavering commitment to sharing messages of hope and encouragement
        continues to inspire and empower individuals. Through his speaking
        engagements, he ignites a fire within people to grow in their faith and
        live out their calling with purpose and conviction. Jon's 20 years of
        experience have equipped him with the skills, insights, and intuition to
        engage and uplift audiences, leaving a lasting impression wherever he
        goes.
      </p>
      <p className={styles.journey__moreInfo__text}>
        By inviting Jon to speak at your church, you can expect engaging and
        impactful presentations that draw from his deep passion for sharing the
        wisdom and teachings of the Bible. Whether it's a small gathering
        seeking guidance and inspiration, a church conference eager to deepen
        their faith, or a congregation yearning for spiritual insights, Jon's
        messages resonate deeply and leave a lasting impact. His ability to
        tailor his delivery to meet the specific needs of your church ensures
        that his words uplift and motivate individuals along their unique faith
        journeys. By hosting Jon Coats as a speaker, you can provide your
        congregation with a memorable and transformative experience that
        inspires them to grow in their faith and live out their calling with
        purpose and conviction.
      </p>
      <a
        className={styles.journey__moreInfo__link}
        target="_blank"
        rel="noreferrer"
        href="mailto:info@thejourneyco.org?subject=Info On Speaking Engagements"
      >
        REQUEST JON TO SPEAK AT YOUR NEXT EVENT
      </a>
    </div>
  );
}
