import { Link } from "react-router-dom";
import styles from "../assets/stylesheets/Footer.module.css";
import jpLogo from "../assets/images/jpLogo.png";

export default function Footer() {
  return (
    <div className={styles.journey__footer__container}>
      <div className={styles.journey__footer__link__container}>
        <Link
          className={styles.journey__footer__link}
          target="_blank"
          rel="noreferrer"
          to="https://www.facebook.com/profile.php?id=100094270597457"
        >
          <i className="fa-brands fa-facebook-f"></i>
        </Link>
        <Link
          className={styles.journey__footer__link}
          target="_blank"
          rel="noreferrer"
          to="https://www.instagram.com/journeycotulsa/"
        >
          <i className="fa-brands fa-instagram"></i>
        </Link>
        <Link
          className={styles.journey__footer__link}
          to="mailto:  info@thejourneyco.org"
        >
          <i className="fa-solid fa-envelope"></i>
        </Link>
        <Link
          className={styles.journey__footer__link}
          to="https://store.918designcompany.com/journey_co/shop/home"
          target="_blank"
          rel="noreferrer"
        >
          <i class="fa-solid fa-cart-shopping"></i>
        </Link>
      </div>
      <p className={styles.journey__footer__text}>
        <i className="fa-regular fa-copyright"></i> 2023 by Journey Co.
      </p>
      <Link
        to="https://www.josiahparkhill.dev/"
        target="_blank"
        rel="noreferrer"
        className={styles.journey__footer__link}
      >
        <div className={styles.journey__jp__link}>
          Designed By
          <img src={jpLogo} alt="jpLogo" className={styles.journey__jpLogo} />
        </div>
      </Link>
    </div>
  );
}
