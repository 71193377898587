import { useState } from "react";
import { Button, Container, Navbar, Stack } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { Outlet } from "react-router-dom";
import styles from "../assets/stylesheets/NavbarHeader.module.css";
import Footer from "./Footer";
import Chat from "./chat/Chat";
import journeyCoLogo from "../assets/images/journeyCoLogo.png";

export default function NavbarHeader({ topRef }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar ref={topRef}>
        <Container fluid className={styles.journey__nav__container}>
          <a href="/">
            <div className={styles.journey__nav__heading}>
              <img
                className={styles.journey__logo}
                src={journeyCoLogo}
                alt="journeyCoLogo"
              />
            </div>
          </a>
          <div className={styles.journey__nav__end}>
            <a
              tabIndex={0}
              target="_blank"
              rel="noreferrer"
              href="http://eepurl.com/ihTg95"
              className={styles.journey__join__button}
            >
              CONNECT
            </a>
            <Button
              className={styles.journey__nav__button}
              onClick={handleShow}
            >
              <i className="fa-solid fa-bars"></i>
            </Button>
          </div>
        </Container>
        <div
          className={`${styles.journey__side__nav} ${
            show ? `${styles.journey__side__nav__show}` : ""
          }`}
        >
          <Button className={styles.journey__nav__x} onClick={handleClose}>
            <i className="fa-solid fa-x"></i>
          </Button>
          <HashLink
            onClick={() => handleClose()}
            to="/#why"
            className={`${styles.journey__nav__link} ${
              styles.journey__nav__link__hidden
            } ${show ? `${styles.journey__nav__link__show}` : ""}`}
          >
            OUR WHY
          </HashLink>
          <HashLink
            onClick={() => handleClose()}
            to="/#engagements"
            className={`${styles.journey__nav__link} ${
              styles.journey__nav__link__hidden
            } ${show ? `${styles.journey__nav__link__show}` : ""}`}
          >
            COACHING
          </HashLink>
          <HashLink
            onClick={() => handleClose()}
            to="/#testimonials"
            className={`${styles.journey__nav__link} ${
              styles.journey__nav__link__hidden
            } ${show ? `${styles.journey__nav__link__show}` : ""}`}
          >
            TESTIMONIALS
          </HashLink>
          <HashLink
            onClick={() => handleClose()}
            to="/#about"
            className={`${styles.journey__nav__link} ${
              styles.journey__nav__link__hidden
            } ${show ? `${styles.journey__nav__link__show}` : ""}`}
          >
            ABOUT
          </HashLink>
          <a
            onClick={() => handleClose()}
            target="_blank"
            rel="noreferrer"
            href="https://secure.subsplash.com/ui/access/589TJ4/"
            className={`${styles.journey__nav__link} ${
              styles.journey__nav__link__hidden
            } ${show ? `${styles.journey__nav__link__show}` : ""}`}
          >
            GIVE
          </a>
          <a
            onClick={() => handleClose()}
            target="_blank"
            rel="noreferrer"
            href="https://store.918designcompany.com/journey_co/shop/home"
            className={`${styles.journey__nav__link} ${
              styles.journey__nav__link__hidden
            } ${show ? `${styles.journey__nav__link__show}` : ""}`}
          >
            SHOP
          </a>
          <a
            href="/"
            className={`${styles.journey__nav__link} ${
              styles.journey__nav__home
            } ${styles.journey__nav__link__hidden} ${
              show ? `${styles.journey__nav__link__show}` : ""
            }`}
          >
            <i className="fa-solid fa-house"></i>
          </a>
        </div>
      </Navbar>
      <Stack>
        <Outlet />
      </Stack>
      <Chat />
      <Footer />
    </>
  );
}
