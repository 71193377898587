import Engagements from "./components/engagement/Engagements";
import HeaderBackground from "./components/HeaderBackground";
import Testimonials from "./components/testimonials/Testimonials";
import About from "./components/about/About";
// import MerchLink from "./components/ui/MerchLink";

export default function AppContainer() {
  return (
    <div>
      <HeaderBackground />
      <Engagements id="engagements" />
      <Testimonials id="testimonials" />
      <About id="about" />
      {/* <MerchLink /> */}
    </div>
  );
}
