import styles from "../../assets/stylesheets/About.module.css";
import jonCoats from "../../assets/images/jonCoats.png";

export default function About(props) {
  return (
    <div id={props.id} className={styles.journey__about__container}>
      <img className={styles.journey__about__image} alt="" src={jonCoats} />
      <p className={styles.journey__about__text}>
        Jon Coats is a pastor, leader, and speaker who launched Journey Co. in
        2023, a ministry that helps believers on the journey of discovering
        their purpose. His hopes are that believers not only discover their
        purpose, but find that a connection with God is the greatest reward.
      </p>
    </div>
  );
}
