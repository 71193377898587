import styles from "../../assets/stylesheets/Testimonials.module.css";

export default function Testimonials(props) {
  return (
    <div id={props.id} className={styles.journey__testimonials__container}>
      <p className={styles.journey__testimonials__header}>TESTIMONIALS</p>
      <div className={styles.journey__testimonial__container}>
        <div
          className={`${styles.journey__testimonial} ${styles.journey__left}`}
        >
          <p className={styles.journey__testimonial__text}>
            <span>"</span>The Missing Link process helped me discover my
            God-given purpose from a young age!<span>"</span>
          </p>
          <p className={styles.journey__testimonial__credit}>
            Steven Coronado - USAF
          </p>
        </div>
        <div
          className={`${styles.journey__testimonial} ${styles.journey__right}`}
        >
          <p className={styles.journey__testimonial__text}>
            <span>"</span>The Missing Link Journey will be a life-changing
            journey, guaranteed!<span>"</span>
          </p>
          <p className={styles.journey__testimonial__credit}>
            Ben Delay - Pastor/Realtor
          </p>
        </div>
        <div
          className={`${styles.journey__testimonial} ${styles.journey__left}`}
        >
          <p className={styles.journey__testimonial__text}>
            <span>"</span>Jon Coats is a natural leader. The Missing Link is an
            exciting opportunity where passion and calling unite<span>"</span>
          </p>
          <p className={styles.journey__testimonial__credit}>
            Josiah Parkhill - Pastor/Software Engineer
          </p>
        </div>
        <div
          className={`${styles.journey__testimonial} ${styles.journey__right}`}
        >
          <p className={styles.journey__testimonial__text}>
            <span>"</span>There is so much heart and years of experience poured
            in The Missing Link. It’s a true testimony to Jon’s walk with God
            that can help all adults grow in their callings and faith journey.
            <span>"</span>
          </p>
          <p className={styles.journey__testimonial__credit}>
            Colleen Morris - Lawyer/Educator
          </p>
        </div>
      </div>
    </div>
  );
}
