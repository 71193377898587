import styles from '../../../assets/stylesheets/MoreInfo.module.css';

export default function EventsMoreInfo() {
  return (
    <div className={styles.journey__moreInfo__container}>
    <p className={styles.journey__moreInfo__header}>EVENTS</p>
      <p className={styles.journey__moreInfo__text}>
        Journey Co. excels in its ability to skillfully run church events,
        offering believers a diverse range of opportunities to cultivate their
        spiritual lives and forge meaningful connections with God and others.
        With a comprehensive understanding of the multifaceted nature of faith,
        the team at Journey Co. carefully designs events that cater to the
        unique needs and preferences of its attendees.
      </p>
      <p className={styles.journey__moreInfo__text}>
        At the heart of Journey Co.'s event planning philosophy is the intention
        to help each individual deepen their personal connection with God.
        Through focused time dedicated to prayer, worship, and scriptural
        exploration, attendees are guided on a transformative journey of
        self-discovery and spiritual growth. Passionate and knowledgeable
        speakers and facilitators lead sessions that provide practical insights,
        techniques, and teachings to enhance participants' prayer life, inspire
        their worship, and unlock the profound wisdom contained within the
        Scriptures. These intentional moments of spiritual guidance and
        education create an environment that encourages personal faith renewal
        and facilitates a more profound understanding of one's relationship with
        God.
      </p>
      <p className={styles.journey__moreInfo__text}>
        While individual spiritual growth is essential, Journey Co. recognizes
        the importance of fostering authentic connections and a sense of
        community among believers. The events organized by Journey Co. foster an
        environment where attendees can share their life experiences, joys, and
        struggles with one another. Through open and honest dialogue,
        individuals find solace in the knowledge that they are not alone in
        their journey of faith. The act of listening, empathizing, and offering
        support through prayer and encouragement becomes a cornerstone of these
        events, creating a space where attendees feel heard, understood, and
        valued.
      </p>
      <p className={styles.journey__moreInfo__text}>
        In addition to interpersonal connections, Journey Co. harnesses the
        power of nature as a catalyst for spiritual experiences. By
        incorporating outdoor activities and retreats, participants are afforded
        the opportunity to commune with God in the awe-inspiring beauty of the
        natural world. Whether it's a serene retreat nestled in the mountains, a
        beachside gathering, or a tranquil garden setting, the events organized
        by Journey Co. allow attendees to escape the distractions of daily life
        and immerse themselves in the tranquility and majesty of creation.
        Through these immersive experiences in nature, individuals find solace,
        inspiration, and a renewed sense of wonder, deepening their connection
        with both God and the world around them.
      </p>
      <p className={styles.journey__moreInfo__text}>
        The impact of Journey Co.'s church events extends far beyond the
        duration of the gatherings themselves. Attendees depart with a newfound
        sense of purpose and encouragement, armed with practical tools and
        insights to navigate their spiritual journey. The ripple effect of these
        events is evident as individuals return to their communities, equipped
        to share their experiences and wisdom, creating a ripple effect that
        strengthens the wider church body. Journey Co.'s commitment to
        facilitating transformative events, where individuals connect with God
        and one another, has garnered a reputation for excellence. Through their
        skillful organization, thoughtful planning, and unwavering dedication,
        Journey Co. continues to provide believers with impactful experiences
        that ignite their faith, foster genuine relationships, and empower them
        to navigate their spiritual journeys with renewed vigor and purpose.
      </p>
      <a
        className={styles.journey__moreInfo__link}
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/profile.php?id=100094270597457"
      >
        STAY UP TO DATE WITH JOURNEY CO. EVENTS
      </a>
    </div>
  );
}
