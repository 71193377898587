import styles from "../../assets/stylesheets/Engagements.module.css";
import Why from "../why/Why";

export default function Engagements(props) {
  return (
    <div>
      <div className={styles.journey__engagements__container}>
        {/* <div className={styles.journey__engagements__circle__container}>
          <div className={styles.journey__engagements__circle}>
            <p>COACHING</p>
          </div>
          <div className={styles.journey__engagements__circle}>
            <p>EVENTS</p>
          </div>
          <div className={styles.journey__engagements__circle}>
            <p>SPEAKING</p>
          </div>
        </div> */}
        <Why id="why" />
        <div id={props.id} className={styles.journey__engagements__coaching}>
          <p className={styles.journey__engagements__section__header}>
            COACHING
          </p>
          <p className={styles.journey__engagements__section__text}>
            Journey Co. offers comprehensive coaching services to help believers
            discover and connect with God's purpose. Their twelve-week program
            provides step-by-step guidance, tailored teachings, and resources.
            Participants receive consistent support from a compassionate coach,
            experiencing transformation and personal growth. Journey Co.'s
            coaching empowers believers to navigate their spiritual journey with
            confidence, deepening their connection with God and living
            purposefully.
          </p>
          <a
            className={styles.journey__engagements__section__link}
            href="/more-info/coaching"
          >
            MORE INFO
          </a>
        </div>
        <div className={styles.journey__engagements__circle__container}>
          <div>
            <div
              className={`${styles.journey__engagements__circle__pricing} ${styles.journey__engagements__circle} ${styles.journey__engagements__circle__disabled}`}
            >
              <p>*E-GROUP</p>
              <span className={styles.journey__engagements__span}></span>
              <p className={styles.journey__engagements__pricing}>
                $75 / one time
              </p>
            </div>
          </div>
          <a
            href="https://mailchi.mp/9dc701000a92/coachingjourney"
            target="_blank"
            rel="noreferrer"
          >
            <div
              className={`${styles.journey__engagements__circle__pricing} ${styles.journey__engagements__circle}`}
            >
              <p>LARGE GROUP</p>
              <span className={styles.journey__engagements__span}></span>
              <p className={styles.journey__engagements__pricing}>
                $25 / per month*
              </p>
            </div>
          </a>
          <a
            href="https://mailchi.mp/9dc701000a92/coachingjourney"
            target="_blank"
            rel="noreferrer"
          >
            <div
              className={`${styles.journey__engagements__circle__pricing} ${styles.journey__engagements__circle}`}
            >
              <p>SMALL GROUP</p>
              <span className={styles.journey__engagements__span}></span>
              <p className={styles.journey__engagements__pricing}>
                $100 / per month*
              </p>
            </div>
          </a>
          <a
            href="https://mailchi.mp/9dc701000a92/coachingjourney"
            target="_blank"
            rel="noreferrer"
          >
            <div
              className={`${styles.journey__engagements__circle__pricing} ${styles.journey__engagements__circle}`}
            >
              <p>1 ON 1</p>
              <span className={styles.journey__engagements__span}></span>
              <p className={styles.journey__engagements__pricing}>
                $250 / per month*
              </p>
            </div>
          </a>
        </div>
        <p
          className={`${styles.journey__addition} ${styles.journey__addition__main}`}
        >
          Click On a Circle to Sign Up for a Course
        </p>
        <p className={styles.journey__addition}>
          <span className={styles.journey__addition__asterisk}>*</span> Large
          Group, Small Group, and 1 on 1 Coaching are in Segments of 3 Months
        </p>
        <p className={styles.journey__addition}>
          <span className={styles.journey__addition__asterisk}>*</span> E-Group
          Coming Soon
        </p>
      </div>
      {/* <div className={styles.journey__engagements__events__span}></div> */}
      {/* <div className={styles.journey__engagements__events}>
        <p className={styles.journey__engagements__section__header}>EVENTS</p>
        <p className={styles.journey__engagements__section__text}>
          Journey Co. organizes church events that deepen believers'
          relationship with God through prayer, worship, and scriptural
          exploration. They prioritize fostering an authentic community where
          attendees can share experiences and find support. Passionate speakers
          provide practical insights, enhancing participants' prayer life,
          worship, and understanding of Scriptures. By incorporating the power
          of nature, attendees connect with God in beautiful outdoor settings.
          Journey Co.'s transformative events empower attendees to strengthen
          the wider church body and share their experiences. Their commitment to
          excellence has established them as a trusted partner on believers'
          spiritual journeys.
        </p>
        <a
          className={styles.journey__engagements__section__link}
          href="/more-info/events"
        >
          MORE INFO
        </a>
      </div> */}
      {/* <div className={styles.journey__engagements__speaking__span}></div> */}
      {/* <div className={styles.journey__engagements__speaking}>
        <p className={styles.journey__engagements__section__header}>SPEAKING</p>
        <p className={styles.journey__engagements__section__text}>
          With extensive leadership experience, Jon passionately shares the
          wisdom of the Bible through engaging presentations that resonate
          deeply with diverse audiences. Whether addressing youth, church
          conferences, or business leaders seeking spiritual insights, Jon
          tailors his delivery to uplift and motivate individuals on their faith
          journeys. His approachability, authenticity, and relatable
          storytelling captivate listeners, leaving a lasting impact. With a
          wealth of knowledge and unwavering commitment to sharing messages of
          hope, Jon inspires individuals to grow in faith and live purposeful
          lives.
        </p>
        <a
          className={styles.journey__engagements__section__link}
          href="/more-info/speaking"
        >
          MORE INFO
        </a>
      </div> */}
    </div>
  );
}
