import styles from "../../assets/stylesheets/Payment.module.css";

export default function Payment() {
  return (
    <div className={styles.journey__payment__container}>
      <stripe-pricing-table
        pricing-table-id="prctbl_1N9IaEAX24t9cxPLBRHWcsrK"
        publishable-key="pk_live_51N9Hs1AX24t9cxPLvAqk2nhnLNV03TKvK3PfLj79Z26UVqhVRW4k1ZsYDe3PSOmvbdm9IyC0RoWO4bXKAYuoY8Ry00n0t1TODo"
      ></stripe-pricing-table>
    </div>
  );
}
